@charset "UTF-8";
html {
  font-size: 15px;
}

a {
  cursor: pointer;
  color: #17a2b8;
}
a:hover {
  color: #117a8b;
}

.table.table-sm thead {
  border: solid #dee2e6;
  border-width: 1px 0;
}
.table.table-sm thead th {
  vertical-align: top;
  border: 0;
}
.table.table-sm tbody {
  border: solid #dee2e6;
  border-width: 0 0 1px;
}
.table.table-sm tbody td {
  vertical-align: top;
  border: 0;
}
.table.table-sm tr:first-child th, .table.table-sm tr:first-child td {
  padding-top: 0.75rem;
}
.table.table-sm tr:last-child th, .table.table-sm tr:last-child td {
  padding-bottom: 0.75rem;
}

.table-sm-hover > tbody:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.login-box .h1 {
  font-size: 2rem;
}
.login-box .h1 span {
  display: block;
  font-size: 1.5rem;
}
.login-box .login-head {
  font-size: 1.25rem;
}

.nav-sidebar .nav-header {
  font-size: 0.75rem;
  padding: 0.75rem 0 0.25rem;
}
.nav-sidebar .nav-separator {
  margin: 0.75rem 0;
  border-top: 1px solid #4f5962;
}
.nav-sidebar .nav-separator span {
  display: none;
}

.main-sidebar .brand-link {
  padding: 12px 0 8px;
}
.main-sidebar .brand-link .brand-text {
  line-height: 1;
}
.main-sidebar .brand-link .version-text {
  font-size: small;
  transition: margin-left 0.3s linear, opacity 0.3s ease, visibility 0.3s ease;
}

.user-panel .profile-angle {
  position: absolute;
  right: 1rem;
  top: 0.7rem;
}

.control-sidebar {
  height: fit-content;
  position: absolute;
  top: 0;
}

[class*=sidebar-dark-] .nav-treeview > .nav-item > .nav-link.active,
[class*=sidebar-dark-] .nav-treeview > .nav-item > .nav-link.active:focus,
[class*=sidebar-dark-] .nav-treeview > .nav-item > .nav-link.active:hover {
  background-color: #17a2b8;
  color: #fff;
}

[class*=sidebar-dark-lime] .nav-treeview > .nav-item > .nav-link.active,
[class*=sidebar-dark-lime] .nav-treeview > .nav-item > .nav-link.active:focus,
[class*=sidebar-dark-lime] .nav-treeview > .nav-item > .nav-link.active:hover {
  background-color: #01ff70;
  color: #1f2d3d;
}

[class*=sidebar-dark-danger] .nav-treeview > .nav-item > .nav-link.active,
[class*=sidebar-dark-danger] .nav-treeview > .nav-item > .nav-link.active:focus,
[class*=sidebar-dark-danger] .nav-treeview > .nav-item > .nav-link.active:hover {
  background-color: #dc3545;
  color: #fff;
}

.content-header h1 {
  font-size: 1.4rem;
  font-weight: bold;
  margin: 4px 0;
}
.content-header .breadcrumb {
  justify-content: flex-end;
  font-size: 12px;
}
.content-header .breadcrumb a {
  color: #17a2b8;
}
.content-header .breadcrumb a:focus, .content-header .breadcrumb a:hover {
  color: #117a8b;
}
.content-header .breadcrumb span {
  color: #666666;
}
.content-header .breadcrumb li + li::before {
  content: "/";
  display: inline-block;
  margin: 0 0.5rem;
}

.card .card-header .dropdown {
  line-height: 1;
}
.card .card-header .dropdown .dropdown-menu {
  line-height: 1.5;
}
.card .card-header .card-title {
  font-size: 1rem;
  font-weight: 300;
}
.card .card-header[data-toggle=collapse] {
  text-decoration: none;
  position: relative;
  padding: 0.75rem 3.25rem 0.75rem 1.25rem;
}
.card .card-header[data-toggle=collapse]::after {
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 1.725rem;
  line-height: 41px;
  font-weight: 900;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
}
.card .card-header[data-toggle=collapse].collapsed::after {
  content: "\f104";
}

label:not(.form-check-label).required::after,
legend:not(.form-check-label).required::after {
  content: "※";
  display: inline-block;
  padding: 0 3px;
  color: #f97979;
}

.form-check-inline .form-check {
  padding-left: 0;
  padding-right: 1.25rem;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.page-link {
  color: #17a2b8;
  background-color: #fff;
}
.page-link:hover {
  color: #117a8b;
}

.revision-header,
.revision-body {
  font-size: 0.8rem;
}

label:not(.form-check-label):not(.custom-file-label) {
  font-weight: 400;
}

.fix_footer {
  font-size: 1rem;
}

.main-footer {
  font-size: 0.75rem;
}

.menu_level_1 > .nav-item > .nav-link {
  padding-left: 1.5rem;
}

.menu_level_2 > .nav-item > .nav-link {
  padding-left: 2rem;
}

.menu_level_3 > .nav-item > .nav-link {
  padding-left: 2.5rem;
}

.menu_level_4 > .nav-item > .nav-link {
  padding-left: 3rem;
}

.menu_level_5 > .nav-item > .nav-link {
  padding-left: 3.5rem;
}

.menu_level_6 > .nav-item > .nav-link {
  padding-left: 4rem;
}

.menu_level_7 > .nav-item > .nav-link {
  padding-left: 4.5rem;
}

.menu_level_8 > .nav-item > .nav-link {
  padding-left: 5rem;
}

.menu_level_9 > .nav-item > .nav-link {
  padding-left: 5.5rem;
}

.menu_level_10 > .nav-item > .nav-link {
  padding-left: 6rem;
}

.select2-container {
  display: block;
}
.select2-container .select2-selection--single {
  height: calc(2.25rem + 2px);
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 0;
  padding-right: 0;
}
.select2-container .select2-selection--multiple {
  height: calc(2.25rem + 2px);
}

.select2-container--default .select2-results__option {
  height: calc(2.25rem + 2px);
}
.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple {
  border: 1px solid #ced4da;
}
.select2-container--default .select2-selection--single .select2-selection__arrow,
.select2-container--default .select2-selection--multiple .select2-selection__arrow {
  height: calc(2.25rem + 2px);
}
.select2-container--default .select2-selection--single .select2-search--inline .select2-search__field:focus,
.select2-container--default .select2-selection--multiple .select2-search--inline .select2-search__field:focus {
  border: 0;
}
.select2-container--default .select2-selection--single .select2-selection__choice__display,
.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  color: #212529;
  padding-left: 10px;
}
.select2-container--default .select2-selection--single .select2-selection__choice__remove,
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 0px;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: #80bdff;
}

input.es-input.suggest {
  background-image: none;
}

.es-list li {
  padding: 0.375rem 0.75rem;
  height: calc(2.25rem + 2px);
}

.sales_attachments {
  padding: 0 5%;
}
.sales_attachments .sales_attachment {
  position: relative;
  border: 1px solid #4f5962;
  margin: 1rem 0;
}
.sales_attachments .sales_attachment img {
  aspect-ratio: 1/1;
  width: 100%;
  object-fit: contain;
}
.sales_attachments .sales_attachment .sales_attachments_icon {
  position: absolute;
  bottom: 2%;
  right: 2%;
}

.sales_detail {
  background-color: #f8f9fa;
}
.sales_detail:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.wrap-chart {
  width: 100%;
  height: auto;
  aspect-ratio: 3/2;
}
@media screen and (min-width: 400px) {
  .wrap-chart {
    aspect-ratio: 2/1;
  }
}
@media screen and (min-width: 768px) {
  .wrap-chart {
    aspect-ratio: 3/1;
  }
}
@media screen and (min-width: 1200px) {
  .wrap-chart {
    aspect-ratio: 4/1;
  }
}