$navbar-nav-link-padding-x: 1rem;

html {
    font-size: 15px;
}

a {
    cursor:pointer;
    color: #17a2b8;
    &:hover {
        color: darken(#17a2b8, 10%);
    }
}
.table.table-sm{
    thead{
        border: solid #dee2e6;
        border-width: 1px 0;
        th {
            vertical-align: top;
            border: 0;
        }
    }
    tbody{
        border: solid #dee2e6;
        border-width: 0 0 1px;
        td {
            vertical-align: top;
            border: 0;
        }
    }
    tr:first-child{
        th,td {
            padding-top: 0.75rem;
        }
    }
    tr:last-child{
        th,td {
            padding-bottom: 0.75rem;
        }
    }
}

.table-sm-hover {
    > tbody:hover {
        background-color: rgba(#000000, .075);
    }
}

.login-box {
    .h1 {
        font-size: 2rem;
        span {
            display: block;
            font-size: 1.5rem;
        }
    }
    .login-head {
        font-size: 1.25rem;
    }
}
.nav-sidebar {
    .nav-header {
        font-size: 0.75rem;
        padding: 0.75rem 0 0.25rem
    }
    .nav-separator {
        margin: 0.75rem 0;
        border-top: 1px solid #4f5962;
        span {
            display: none;
        }
    }
}
.main-sidebar{
    .brand-link{
        padding: 12px 0 8px;
        .brand-text {
            line-height: 1;
        }
        .version-text {
            font-size: small;
            transition: margin-left .3s linear,opacity .3s ease,visibility .3s ease;
        }
    }
}
.user-panel .profile-angle {
    position: absolute;
    right: 1rem;
    top: .7rem;
}
.control-sidebar {
    height: fit-content;
    position: absolute;
    top: 0;
}
[class*=sidebar-dark-] .nav-treeview > .nav-item > .nav-link.active, 
[class*=sidebar-dark-] .nav-treeview > .nav-item > .nav-link.active:focus,
[class*=sidebar-dark-] .nav-treeview > .nav-item > .nav-link.active:hover {
    background-color: #17a2b8;
    color: #fff;
}
[class*=sidebar-dark-lime] .nav-treeview > .nav-item > .nav-link.active, 
[class*=sidebar-dark-lime] .nav-treeview > .nav-item > .nav-link.active:focus,
[class*=sidebar-dark-lime] .nav-treeview > .nav-item > .nav-link.active:hover {
    background-color: #01ff70;
    color: #1f2d3d;
}
[class*=sidebar-dark-danger] .nav-treeview > .nav-item > .nav-link.active, 
[class*=sidebar-dark-danger] .nav-treeview > .nav-item > .nav-link.active:focus,
[class*=sidebar-dark-danger] .nav-treeview > .nav-item > .nav-link.active:hover {
    background-color: #dc3545;
    color: #fff;
}
.content-header {
    h1 {
        font-size: 1.4rem;
        font-weight: bold;
        margin: 4px 0;
    }
    .breadcrumb {
        justify-content: flex-end;
        font-size: 12px;
        a {
            color: #17a2b8;
            &:focus,
            &:hover {
                color: darken(#17a2b8, 10%);
            }
        }
        span{
            color: #666666;
        }
        li+li::before {
            content: '/';
            display: inline-block;
            margin: 0 0.5rem;

        }
    }
}

// Custom Card Styling

.card {
    .card-header {
        // Format Dropdowns in Card Headings
        .dropdown {
            line-height: 1;
            .dropdown-menu {
                line-height: 1.5;
            }
        }
        .card-title {
            font-size: 1rem;
            font-weight: 300;
        }
    }
    // Collapsable Card Styling
    .card-header[data-toggle="collapse"] {
        text-decoration: none;
        position: relative;
        padding: 0.75rem 3.25rem 0.75rem 1.25rem;
        &::after {
            position: absolute;
            right: 0;
            top: 0;
            padding-right: 1.725rem;
            line-height: 41px;
            font-weight: 900;
            content: '\f107';
            font-family: 'Font Awesome 5 Free';
        }
        &.collapsed {
            &::after {
                content: '\f104';
            }
        }
    }
}

label:not(.form-check-label).required::after,
legend:not(.form-check-label).required::after {
    content: '\203b';
    display: inline-block;
    padding: 0 3px;
    color: #f97979;
}
.form-check-inline .form-check {
    padding-left: 0;
    padding-right: 1.25rem;
}

.page-item.active .page-link {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}
.page-link {
    color: #17a2b8;
    background-color: #fff;
    &:hover {
        color: darken(#17a2b8, 10%);
    }
}

.revision-header,
.revision-body {
    font-size: 0.8rem;
}

label:not(.form-check-label):not(.custom-file-label){
    font-weight: 400;
}
.fix_footer {
    font-size: 1rem;
}
.main-footer {
    font-size: 0.75rem;
}

@for $level from 1 to 11 {
    .menu_level_#{$level} > .nav-item > .nav-link {
        padding-left: $navbar-nav-link-padding-x + 0.5rem * $level;
    }
}

.select2-container {
    display: block;
    .select2-selection--single {
        height: calc(2.25rem + 2px);
        .select2-selection__rendered {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .select2-selection--multiple {
        height: calc(2.25rem + 2px);
    }
}
.select2-container--default {
    .select2-results__option {
        height: calc(2.25rem + 2px);
    }
    .select2-selection--single,
    .select2-selection--multiple {
            border: 1px solid #ced4da;
        .select2-selection__arrow {
            height: calc(2.25rem + 2px);
        }
        .select2-search--inline {
            .select2-search__field:focus {
                border: 0;
            }
        }
        .select2-selection__choice__display {
            color: #212529;
            padding-left: 10px;
        }
        .select2-selection__choice__remove {
            margin-left: 0px;
        }
    }
    &.select2-container--focus{
        .select2-selection--multiple{
            border-color: #80bdff;
        }
    }
}

input.es-input.suggest {
    background-image: none;
}
.es-list li {
    padding: 0.375rem 0.75rem;
    height: calc(2.25rem + 2px);
}

.sales_attachments {
    padding: 0 5%;
    .sales_attachment {
        position: relative;
        border: 1px solid #4f5962;
        margin: 1rem 0;
        img {
            aspect-ratio: 1 / 1;
            width: 100%;
            object-fit: contain;
        }
        .sales_attachments_icon {
            position: absolute;
            bottom: 2%;
            right: 2%;
        }
    }
}
.sales_detail {
    background-color: #f8f9fa;
    &:hover {
        background-color: rgba(#000000, .075);
    }
}
.wrap-chart {
    width: 100%;
    height: auto;
    aspect-ratio: 3 / 2;
    @media screen and (min-width: 400px) {
        aspect-ratio: 2 / 1;
    }
    @media screen and (min-width: 768px) {
        aspect-ratio: 3 / 1;
    }
    @media screen and (min-width: 1200px) {
        aspect-ratio: 4 / 1;
    }
}